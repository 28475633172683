// スマホの時に新着とランキングをタブで切り替えるためのレイアウト変更処理
function toggleDisplayList() {

  const ACTIVE_TAB_CLASS = 'p-blog__toggle__tab--active';
  const ACTIVE_LIST_CLASS = 'p-toggle-list--active';

  let $toggleTabs = document.getElementsByClassName('p-blog__toggle__tab');
  if (!$toggleTabs) return;
  $toggleTabs = Array.from($toggleTabs);

  let $toggleLists = document.getElementsByClassName('p-toggle-list');
  if (!$toggleLists) return;
  $toggleLists = Array.from($toggleLists);

  $toggleTabs.forEach((tab) => {
    tab.addEventListener('click', () => {
      $toggleTabs.forEach((tab) => {
        tab.classList.toggle(ACTIVE_TAB_CLASS);
      });
      $toggleLists.forEach((list) => {
        list.classList.toggle(ACTIVE_LIST_CLASS);
      });
    });
  });

  window.addEventListener('resize', (event) => {
    if (window.innerWidth > 1080) {
      const $currentActiveTab = document.querySelector(`.${ACTIVE_TAB_CLASS}`);
      if (!$currentActiveTab) return;
      $currentActiveTab.classList.remove(ACTIVE_TAB_CLASS);

      const $currentActiveList = document.querySelector(`.${ ACTIVE_LIST_CLASS }`);
      if (!$currentActiveList) return;
      $currentActiveList.classList.remove(ACTIVE_LIST_CLASS);

      $toggleTabs[0].classList.add(ACTIVE_TAB_CLASS);
      $toggleLists[0].classList.add(ACTIVE_LIST_CLASS);
    }
  })
}

export { toggleDisplayList };