import gsap from 'gsap';
import { EASING } from './const.js';

// グローバルメニュー開閉処理
function toggleMenuButton() {

  const $openMenuButton = document.getElementById('open-menu');
  const $closeMenuButton = document.getElementById('close-menu');
  const $body = document.querySelector('body');
  const $targetItems = document.getElementsByClassName('a-menu');
  const $grandMenu = document.getElementById('grand-menu');
  let isOpen = false;

  $openMenuButton.addEventListener('click', (event) => {

    if (isOpen) return;

    $body.classList.add('fixed');

    const tl = gsap.timeline();

    tl.to('#grand-menu', {
      duration: 0.5,
      ease: EASING,
      clipPath: 'circle(150% at 93% 11%)',
      visibility: 'visible',
    }).from('.a-menu', {
      duration: 0.3,
      opacity: 0,
      y: 20,
      stagger: 0.02,
    }, '-=0.3').to('#close-menu', {
      scale: 1,
    }, '-=0.3');

    isOpen = true;

  });

  // 閉じるボタンを押した時のイベント
  $closeMenuButton.addEventListener('click', (event) => {
    $body.classList.remove('fixed');

    const tl = gsap.timeline({
      // タイムライン完了後のコールバック処理
      onComplete: () => {
        Array.from($targetItems).forEach((item) => {
          item.style.opacity = 1;
        });
        $grandMenu.style.visibility = 'hidden';
        $grandMenu.style.opacity = 1;
        isOpen = false;
      }
    });
    tl.to('.a-menu', {
      duration: 0.3,
      opacity: 0,
    }).to('#grand-menu', {
      duration: 0.5,
      ease: EASING,
      clipPath: 'circle(0% at 93% 11%)',
      opacity: 0,
    }, '-=0.1').to('#close-menu', {
      duration: 0.3,
      scale: 0,
    }, '-=0.3');

  });
}

export { toggleMenuButton }
