// IMPORT STYLES
import 'modern-css-reset/dist/reset.css';
import 'swiper/css';
import './style.scss';

// IMPORT SCRIPTS
import { createTopSwiper, createInfoSwiper } from './js/slideshow';
import { toggleMenuButton } from './js/menu.js';
import { toggleDisplayList } from './js/responsive.js';
import { smoothScroll, resizeHeader } from './js/scroll.js';
import { loadImages } from './js/loading.js';

document.addEventListener('DOMContentLoaded', () => {
  resizeHeader();
  smoothScroll();
  toggleMenuButton();
  createInfoSwiper();
  toggleDisplayList();
  loadImages(createTopSwiper());
});
