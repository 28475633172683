import gsap from 'gsap';
import { EASING } from './const.js';

function loadImages(swiper) {

  // クラスに「lazyload」を持つ画像の読み込みが完了次第、ローディング画面を非表示にする
  let $slideImages = document.getElementsByClassName('lazyload');
  $slideImages = Array.from($slideImages);

  const promiseArray = [];

  $slideImages.forEach((image) => {
    promiseArray.push(loadImage(image));
  });

  Promise.all(promiseArray).then(() => {
    const tl = gsap.timeline();

    tl.to('.c-loading', {
      duration: 0.5,
      ease: EASING,
      opacity: 0,
    }).to('.c-loading', {
      visibility: 'hidden',
    }).to('.c-loading', {
      display: 'none',
    });

    if (swiper) {
      // スライドショー開始
      swiper.autoplay.delay = 5000;
      swiper.autoplay.disableOnInteraction = false;
      swiper.autoplay.start();
    }
  });

}

function loadImage(image) {
  return new Promise((resolve) => {
    const imgObj = new Image();
    imgObj.src = image.src;
    imgObj.onload = function () {
      image.classList.add('loaded');
      resolve();
    };
  });
}

export { loadImages };
