import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';
Swiper.use([Navigation, Pagination, Autoplay]);


// トップページのメインビジュアルスライドショー
const createTopSwiper = () => {

  if (!document.querySelector('.mv-swiper')) return null;

  const swiper = new Swiper('.mv-swiper', {
    slidesPerView: 'auto',
    centeredSlides: true,
    spaceBetween: 30,
    loop: true,
    speed: 1000,
    pagination: {
      el: '.p-mv__pagination',
      type: 'bullets',
      clickable: true,
    },
    navigation: {
      nextEl: '.p-mv__button-next',
      prevEl: '.p-mv__button-prev',
    },
    breakpoints: {
      768: {
        spaceBetween: 60,
      },
    },
  });

  return swiper;
};

// トップページのINFORMATIONスライドショー
const createInfoSwiper = () => {

  if (!document.querySelector('.info-swiper')) return null;

  const swiper = new Swiper('.info-swiper', {
    slidesPerView: 'auto',
    spaceBetween: 25,
    freeMode: true,
    navigation: {
      nextEl: '.p-info__button-next',
      prevEl: '.p-info__button-prev',
    },
  });

  return swiper;
};

export {createTopSwiper, createInfoSwiper}