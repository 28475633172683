function smoothScroll() {
  const smoothScrollTrigger = document.querySelectorAll('a[href^="#"]');
  for (let i = 0; i < smoothScrollTrigger.length; i++) {
    smoothScrollTrigger[i].addEventListener('click', (e) => {
      e.preventDefault();
      let href = smoothScrollTrigger[i].getAttribute('href'); // 各a要素のリンク先を取得
      let targetElement = document.getElementById(href.replace('#', '')); // リンク先の要素（コンテンツ）を取得
      const rect = targetElement.getBoundingClientRect().top; // ブラウザからの高さを取得
      const offset = window.pageYOffset; // 現在のスクロール量を取得
      const gap = document.querySelector('.l-header').offsetHeight; // 固定ヘッダー分の高さ
      const target = rect + offset - gap; //最終的な位置を割り出す

      window.scrollTo({
        top: target,
        behavior: 'smooth',
      });

    });
  }
}

function resizeHeader() {
  document.addEventListener('scroll', () => {
    const $header = document.querySelector('.l-header');
    const $headerLogo = document.querySelector('.p-header__logo__text');
    if (window.scrollY > 30) {
      $header.classList.add('l-header--downsize');
      $headerLogo.classList.add('p-header__logo__text--downsize');
    } else {
      $header.classList.remove('l-header--downsize');
      $headerLogo.classList.remove('p-header__logo__text--downsize');
    }
  });
}

export { smoothScroll, resizeHeader };
